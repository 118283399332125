[data-v-deaa8546] .el-dialog__body {
  text-align: left !important;
}
[data-v-deaa8546] .el-dialog__header {
  text-align: left;
  border-bottom: solid 1px #dcdfe6;
}
[data-v-deaa8546] .el-tab-pane {
  padding-right: 15px;
}
[data-v-deaa8546] .el-tabs__content {
  padding-left: 10px;
}
[data-v-deaa8546] .el-collapse-item__header {
  background-color: #f1f6fc;
  padding-left: 8px;
}
[data-v-deaa8546] .el-collapse-item__content {
  padding: 20px 0 10px;
}
[data-v-deaa8546] .el-collapse {
  border-bottom: none;
}
.trigger[data-v-deaa8546] .el-collapse-item__wrap {
  border-bottom: none;
}
.el-row[data-v-deaa8546] {
  height: 80px;
}
.el-row .el-col[data-v-deaa8546] {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.el-row .el-col .el-form-item[data-v-deaa8546] {
      -webkit-box-flex: 1;
          -ms-flex: 1;
              flex: 1;
}
